<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li v-if="$store.state.User.jibieId==0">
          <p>切换门店：</p>
          <select v-model="form.fenpeimendianId" @change="addDate">
            <option :value="0">全部门店</option>
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <p>统计时间：</p>
          <input type="date" @change="addDate(1)" v-model="form.start"><span> &nbsp;-&nbsp; </span>
          <input type="date" @change="addDate(1)" v-model="form.end">
        </li>
        <li>
          <p>快捷查询：</p>
          <select v-model="form.Sdate" @change="addDate">
            <option v-for="item in dateList" :key="item.id" :value="item.name">{{item.name}}</option>
          </select>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="TBill_all">
      <div class="TBill">
        <div class="TBill_a">
          <div class="TBill_a_1">
            <div class="table_cell">{{form.Sdate}}入账收入<br>￥<strong>{{countFloat(CData.shouru.data)}}</strong>元</div>
          </div>
          <div class="TBill_a_6">
            <div class="table_cell">
              <div class="TBill_a_2_2">充值:￥<strong>{{countFloat(CData.shouru.count.chongzhi)}}</strong>元</div>
              <div class="TBill_a_2_2">消费:￥<strong>{{countFloat(CData.shouru.count.xiaofei+CData.shouru.count.xiaofei2)}}</strong>元</div>
              <div class="TBill_a_2_2">日常:￥<strong>{{countFloat(CData.shouru.count.richang)}}</strong>元</div>
            </div>
          </div>
          <div class="TBill_a_2">
            <div class="table_cell">
              <div class="TBill_a_2_1" v-for="item in zffsList" :key="item.id">{{item.name}}:￥<strong>{{countFloat(CData.shouru.son['sr'+item.id])}}</strong>元</div>
            </div>
          </div>
          <div class="TBill_a_3">
            <div class="table_cell">{{form.Sdate}}划卡<br>￥<strong>{{countFloat(CData.shouru.hk)}}</strong>元</div>
          </div>
          <div class="TBill_a_4">
            <div class="TBill_a_4_1">
              <div class="table_cell">{{form.Sdate}}不入账收入<br>￥<strong>{{countFloat(CData.shouru.buru.data)}}</strong>元</div>
            </div>
            <div class="TBill_a_4_2">
              <div class="table_cell">
                <div class="TBill_a_2_2">退货:￥<strong>{{countFloat(CData.shouru.buru.son.tuihuo)}}</strong>元</div>
                <div class="TBill_a_2_2">其他:￥<strong>{{countFloat(CData.shouru.buru.son.qita)}}</strong>元</div>
              </div>
            </div>
          </div>
        </div>
        <div class="TBill_a">
          <div class="TBill_a_1 TBill_a_zc_1">
            <div class="table_cell">{{form.Sdate}}入账支出<br>￥<strong>{{countFloat(CData.zhichu.data)}}</strong>元</div>
          </div>
          <div class="TBill_a_6 TBill_a_zc_6">
            <div class="table_cell">
              <div class="TBill_a_2_2">采购:￥<strong>{{countFloat(CData.zhichu.count.caigou)}}</strong>元</div>
              <div class="TBill_a_2_2">其他:￥<strong>{{countFloat(CData.zhichu.count.qita)}}</strong>元</div>
            </div>
          </div>
          <div class="TBill_a_2 TBill_a_zc_2">
            <div class="table_cell">
              <div class="TBill_a_2_1" v-for="item in zffsList" :key="item.id">{{item.name}}:￥<strong>{{countFloat(CData.zhichu.son['sr'+item.id])}}</strong>元</div>
            </div>
          </div>
          <div class="TBill_a_3 TBill_a_zc_3">
            <div class="table_cell" v-if="$store.state.User.daoqishijian<0"><strong>非VIP无提成统计</strong></div>
            <div class="table_cell" v-else>{{form.Sdate}}提成<br>￥<strong>{{countFloat(CData.zhichu.tc)}}</strong>元</div>
          </div>
          <div class="TBill_a_4 TBill_a_zc_4">
            <div class="TBill_a_4_1">
              <div class="table_cell">{{form.Sdate}}不入账支出<br>￥<strong>{{countFloat(CData.zhichu.buru.data)}}</strong>元</div>
            </div>
            <div class="TBill_a_4_2">
              <div class="table_cell">
                <div class="TBill_a_2_2">采购:￥<strong>{{countFloat(CData.zhichu.buru.son.caigou)}}</strong>元</div>
                <div class="TBill_a_2_2">其他:￥<strong>{{countFloat(CData.zhichu.buru.son.qita)}}</strong>元</div>
              </div>
            </div>
          </div>
        </div>
        <div class="TBill_a">
          <div class="TBill_a_1 TBill_a_yk_1">
            <div class="table_cell">{{form.Sdate}}入账余款<br>￥<strong>{{countFloat(CData.yukuan.data)}}</strong>元</div>
          </div>
          <div class="TBill_a_5 TBill_a_yk_2">
            <div class="table_cell">
              <div class="TBill_a_2_1" v-for="item in zffsList" :key="item.id">{{item.name}}:￥<strong>{{countFloat(CData.yukuan.son['sr'+item.id])}}</strong>元</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/http/axios'
import moment from 'moment'
import {zffsList} from '@/data/data'
export default {
  name: 'tradeBill',
  data() {
    return {
      zffsList,
      form:{
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
        Sdate:'今日',
        start:null,
        end:null,
      },
      storeList:[],
      dateList:[
        {
          id:1,
          name:'今日',
          startDate:moment().format("YYYY-MM-DD"),
          endDate:null,
        },
        {
          id:2,
          name:'昨日',
          startDate:moment().add(-1,"d").format("YYYY-MM-DD"),
          endDate:moment().add(-1,"d").format("YYYY-MM-DD"),
        },
        {
          id:3,
          name:'本周',
          startDate:moment().startOf("W").format("YYYY-MM-DD"),
          endDate:null,
        },
        {
          id:4,
          name:'上周',
          startDate:moment().add(-1,"W").startOf("W").format("YYYY-MM-DD"),
          endDate:moment().add(-1,"W").endOf("W").format("YYYY-MM-DD"),
        },
        {
          id:5,
          name:'本月',
          startDate:moment().startOf("M").format("YYYY-MM-DD"),
          endDate:null,
        },
        {
          id:6,
          name:'上月',
          startDate:moment().add(-1,"M").startOf("M").format("YYYY-MM-DD"),
          endDate:moment().add(-1,"M").endOf("M").format("YYYY-MM-DD"),
        },

      ],
      CData:{
        shouru:{
          data:0,
          count:{
            chognzhi:0,
            xiaofei:0,
            xiaofei2:0,
            richang:0,
          },
          son:{
            sr2:0,
            sr3:0,
            sr4:0,
            sr5:0,
            sr6:0,
            sr7:0,
            sr8:0,
            sr9:0,
            sr10:0,
            sr11:0,
            sr12:0,
            sr99:0,
          },
          hk:0,
          buru:{
            data:0,
            son:{
              tuihuo:0,
              qita:0,
            }
          }
        },
        zhichu:{
          data:0,
          count:{
            caigou:0,
            qita:0,
          },
          son:{
            sr2:0,
            sr3:0,
            sr4:0,
            sr5:0,
            sr6:0,
            sr7:0,
            sr8:0,
            sr9:0,
            sr10:0,
            sr11:0,
            sr12:0,
            sr99:0,
          },
          tc:0.00,
          buru:{
            data:0,
            son:{
              caigou:0,
              qita:0,
            }
          }
        },
        yukuan:{
          data:0,
          son:{
            sr2:0,
            sr3:0,
            sr4:0,
            sr5:0,
            sr6:0,
            sr7:0,
            sr8:0,
            sr9:0,
            sr10:0,
            sr11:0,
            sr12:0,
            sr99:0,
          },
        }
      }
    }
  },
  created() {
    if(this.$store.state.User.jibieId==0) this.getStore()
    else this.addDate()
  },
  computed:{},
  components: {},
  mounted() {},
  methods: {
    countFloat(number){
      if(number) return parseFloat(number).toFixed(2)
      else return '0.00'
    },
    addDate(on){
      let d=this.dateList.find(e=>e.name==this.form.Sdate)
      if(on==1){
        this.form.Sdate=""
        d={
          startDate:moment(this.form.start).isValid()?moment(this.form.start).format("YYYY-MM-DD"):null,
          endDate:moment(this.form.end).isValid()?moment(this.form.end).format("YYYY-MM-DD"):null
        }
      }else{
        this.form.start=null
        this.form.end=null
      }
      if(d){
        axios.post('/dataCount/getTradeBill',{
          fenpeimendianId:this.form.fenpeimendianId,
          startDate:d.startDate,
          endDate:d.endDate,
        }).then((res) => {
          if(res.code===5000){
            let {czCount,xfCount,xf2Count,rczcCount,rcjzCount,ticheng}=res.data
            this.CountData(czCount,xfCount,xf2Count,rczcCount,rcjzCount,ticheng)
          }else{
            this.$Mesg({content: res.msg,errOrSuc: 'err'})
          }
        })
      } else this.$Mesg({content:'数据出错！',errOrSuc: 'err'})
    },
    CountData(czCount,xfCount,xf2Count,rczcCount,rcjzCount,ticheng){
      let data={
        shouru:{
          data:0,
          count:{
            chongzhi:0,
            xiaofei:0,
            xiaofei2:0,
            richang:0,
          },
          son:{
            sr2:0,
            sr3:0,
            sr4:0,
            sr5:0,
            sr6:0,
            sr7:0,
            sr8:0,
            sr9:0,
            sr10:0,
            sr11:0,
            sr12:0,
            sr99:0,
          },
          hk:0,
          buru:{
            data:0,
            son:{
              tuihuo:0,
              qita:0,
            }
          }
        },
        zhichu:{
          data:0,
          count:{
            caigou:0,
            qita:0,
          },
          son:{
            sr2:0,
            sr3:0,
            sr4:0,
            sr5:0,
            sr6:0,
            sr7:0,
            sr8:0,
            sr9:0,
            sr10:0,
            sr11:0,
            sr12:0,
            sr99:0,
          },
          tc:0.00,
          buru:{
            data:0,
            son:{
              caigou:0,
              qita:0,
            }
          }
        },
        yukuan:{
          data:0,
          son:{
            sr2:0,
            sr3:0,
            sr4:0,
            sr5:0,
            sr6:0,
            sr7:0,
            sr8:0,
            sr9:0,
            sr10:0,
            sr11:0,
            sr12:0,
            sr99:0,
          },
        }
      }
      czCount.forEach(e => {
        data.shouru.data+=e.Count
        data.shouru.count.chongzhi+=e.Count
        data.shouru.son[`sr${e.zhifufangshi}`]+=e.Count
      });
      xfCount.forEach(e => {
        if(e.zffs==1){
          data.shouru.hk+=e.Count
        }else{
          data.shouru.data+=e.Count
          data.shouru.count.xiaofei+=e.Count
          data.shouru.son[`sr${e.zffs}`]+=e.Count
        }
      });
      xf2Count.forEach(e => {
        data.shouru.data+=e.Count
        data.shouru.count.xiaofei2+=e.Count
        data.shouru.son[`sr${e.zffs2}`]+=e.Count
      });
      rcjzCount.forEach(e => {
        if(e.isInBill==1){
          data.shouru.buru.data+=(e.Count-e.TCount)
          if(e.leixing==1){
            data.shouru.buru.son.tuihuo+=(e.Count-e.TCount)
          }else{
            data.shouru.buru.son.qita+=(e.Count-e.TCount)
          }
        }else{
          data.shouru.data+=(e.Count-e.TCount)
          data.shouru.count.richang+=(e.Count-e.TCount)
          data.shouru.son[`sr${e.isInBill}`]+=(e.Count-e.TCount)
        }
      });
      rczcCount.forEach(e => {
        if(e.isInBill==1){
          data.zhichu.buru.data+=(e.Count-e.TCount)
          if(e.leixing==1){
            data.zhichu.buru.son.caigou+=(e.Count-e.TCount)
          }else{
            data.zhichu.buru.son.qita+=(e.Count-e.TCount)
          }
        }else{
          data.zhichu.data+=(e.Count-e.TCount)
          data.zhichu.son[`sr${e.isInBill}`]+=(e.Count-e.TCount)
          if(e.leixing==1){
            data.zhichu.count.caigou+=(e.Count-e.TCount)
          }else{
            data.zhichu.count.qita+=(e.Count-e.TCount)
          }
        }
      });
      ticheng.forEach(e => {
        data.zhichu.tc+=(e.Count?e.Count:0)
      });
      data.yukuan.data=data.shouru.data-data.zhichu.data
      this.zffsList.forEach(e=>{
        data.yukuan.son["sr"+e.id]=data.shouru.son["sr"+e.id]-data.zhichu.son["sr"+e.id]
      })
      data.zhichu.tc=data.zhichu.tc.toFixed(2)
      this.CData=data
    },
    getDateTime(sr){
      if(sr) return moment(sr).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
          this.addDate()
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    dataFormat(str){
      if(str) return moment(str).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
  },
}
</script>
